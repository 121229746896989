<script lang="ts" setup>
const dialog = ref<HTMLDialogElement>()
const show = defineModel('show', {
  type: Boolean,
  default: false,
})
watch(show, (value) => {
  if (value) {
    dialog.value?.showModal()
  }
  else {
    dialog.value?.close()
  }
})

const box = ref<HTMLDivElement>()
onClickOutside(box, (_) => {
  show.value = false
})
</script>

<template>
  <dialog ref="dialog" class="modal">
    <div ref="box" class="modal-box">
      <slot />
    </div>
  </dialog>
</template>
